

/* Header.css */
.header-navbar {
  background-color: #e6e3e3; /* Set your desired background color */
  padding-bottom: 0 !important;
}

.header-logo {
  max-width: 250px; /* Adjust the maximum width as needed */
  height: auto;
}

/* CSS for mobile screens */
@media (max-width: 768px) {
  .header-logo {
    max-width: 100%; /* Make the image take up 100% of the container's width */
  }
}


.nav-links {
  display: flex;
  justify-content: center;
  background-color: #00446a; /* Set your desired background color */
}

.nav-links a {
  margin-right: 6em; /* Adjust the margin between links as needed */
  text-decoration: none;
  font-size: 1.1em;
  color: #ffffff; /* Set your desired link color */
  
  /* Add the following styles to center the text horizontally */
  display: flex;
  align-items: center;
}

/* Custom style for the search bar */
.searchbar-container .input-group {
  height: 40px; /* Adjust the height as needed */
  font-size: 15px; /* Adjust the font size as needed */
  width: 100%; /* Make the search bar 100% of the container's width */
  width: 400px;
  flex-grow: 1;
}

/* Additional style for the second header-cont div (navigation) */
.header-cont-nav {
  flex-grow: 1; 
}

.logo-container {
  display: flex;
  justify-content: center;
}

.login-icon {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.login-col {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.shopping-cart-icon {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.shopping-cart-col {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.shopping-cart-col:hover {
  cursor: pointer;
}

.nav-dropdown {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  font-size: larger;
}

.dropdown-toggle  {
  color: #ffffff !important;
}

.nav-dropdown .dropdown-menu {
  justify-content: flex-start;
  text-align: center;
  width: 50%;
  left: 0 !important; 
  right: auto !important; 
}

.nav-container {
  background-color: #00446a; /* Set your desired background color */
  padding: 10px;
  min-width: 100%;
}
