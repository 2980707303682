
/* Remove the highlight border from the FormControl */
.input-group .form-control[type="text"] {
    box-shadow: none;
    border-color: #ced4da; /* Add the desired border color */
  }
  
  .quantity-cont {
    display: flex;
    align-items: center;
  }

  .quantity-text {
    margin-right: 30px;
    margin-top: 0; /* Remove default margin */
    margin-bottom: 0; /* Remove default margin */
    text-align: center;
    font-size: 24px;
  }
