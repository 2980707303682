.landing-info {
    text-align: center;
    padding: 100px;
  }
  
  .info-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .info-paragraph {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
  }

  .frontpage-grid {
    margin-top: 50px;
  }

  .frontpage-grid h1 {
    text-align: center;
  }

  .spacer {
    margin-top: 50px;
  }