/* General container settings */
.services-page {
    padding: 0;
    margin: 0;
  }
  
  /* Service row */
  .service-row {
    margin-top: 5px;
    margin-bottom: 70px; /* Adjust spacing between services */
  }
  
  /* Service image container */
  .service-image-container {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 80vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Text overlay on the image */
  .service-text-overlay {
    position: absolute;
    color: white;
    text-align: center;
    background: rgba(0, 0, 0, 0.5); /* Darken the background for better readability */
    padding: 30px;
    width: 100%;
    max-width: 600px;
  }
  
  /* Heading and paragraph styling */
  .service-text-overlay h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .service-text-overlay p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  /* Button styling */
  .service-button {
    background-color: #003366;
    border: none;
    font-size: 1.1rem;
    padding: 10px 20px;
  }
  
  .service-button:hover {
    background-color: #0055cc;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .service-image-container {
      height: 50vh;
    }
  
    .service-text-overlay h2 {
      font-size: 1.8rem;
    }
  
    .service-text-overlay p {
      font-size: 1rem;
    }
  
    .service-button {
      font-size: 1rem;
      padding: 8px 16px;
    }
  }
  
  .service-section {
    padding: 50px 0;
  }
  
  .service-spacer {
    height: 50px;
  }
  
  .service-section-1 {
    background-color: #f5f5f5;
  }
  
  .service-section-2 {
    background-color: #f0f8ff;
  }
  
  .service-section-3 {
    background-color: #fff5e6;
  }
  
  .service-section-4 {
    background-color: #e6f0ff;
  }
  