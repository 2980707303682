.checkout-cont {
    margin-top: 30px;
    margin-bottom: 50px;
    padding-left: 0; /* Remove left padding */
    padding-right: 0; /* Remove right padding */
    width: 100%; /* Full width for the container */
}

.checkout-left,
.checkout-right {
    background-color: #f9f9f9;
    padding: 0px;
    border-radius: 8px;
    margin: 0; /* Ensure no margin on the sides */
}

.checkout-left {
    width: 100%;
}

.checkout-right {
    background-color: #ffffff;
    padding: 0;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling if table overflows */
}

.checkout-left-cont {
    flex: 2;
}

.checkout-right-cont {
    flex: 1;
}

.checkout-title {
    margin-bottom: 40px;
}

.checkout-order-title {
    margin-top: 40px;
    margin-bottom: 35px;
}

.order-type-select {
    margin-bottom: 30px;
}

.checkout-cont .form-label {
    margin-top: 5px; /* Adjust the value as needed */
}


/* Order Summary Section */
.order-summary {
    position:  sticky;
    top: 200px;
    align-self: flex-start;
    z-index: 1000;
    background-color: #f0ecec;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}
  
.order-summary-title {
    font-size: 1.35rem;
    font-weight: bold;
    margin-bottom: 25px;
}
  
.order-summary-details {
    width: 100%;
}
  
  .order-summary-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .order-summary-item.total {
    font-size: 1.2em;
  }
  
  
  .order-summary-divider {
    margin-top: 35px;
  }
  
  .order-now-button {
    width: 100%;
    margin-top: 20px;
  }
  

@media (max-width: 768px) {
    .checkout-left,
    .checkout-right {
        width: 100%;
        margin: 0 0 20px 0; /* Stack columns on smaller screens */
    }
    
    .checkout-cont {
        padding-left: 0;
        padding-right: 0;
    }

    .order-now-button {
        width: 100%; /* Button spans the entire width on small screens */
    }
}
