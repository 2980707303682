.technical-support-container {
    margin: 40px auto;
    max-width: 600px;
  }
  
  .technical-support-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .submit-button {
    margin-top: 20px;
  }
  