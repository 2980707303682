/* General page styling */
.contact-page {
    padding: 40px 0;
  }
  
  /* Contact form styling */
  .contact-page form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Contact Information styling */
  .contact-info {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .contact-info h2 {
    margin-top: 0;
  }
  
  /* Google Maps styling */
  .contact-page iframe {
    margin-top: 20px;
    border-radius: 5px;
    height: 350px;
  }
  
  /* Button styling */
  .contact-page button {
    width: 100%;
    padding: 10px;
  }
  
  @media (max-width: 768px) {
    /* For smaller devices, make the layout more vertical */
    .contact-info {
      margin-top: 40px;
    }
  
    .contact-page button {
      width: 100%;
    }
  }
  