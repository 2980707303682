.wider-column {
    width: 50%; /* Adjust the width as needed */
  }
  
.cart-item-thumbnail {
  height: 100px;
}

.table-quantity, .table-price, .table-actions {
  vertical-align: middle;
}