/* General Footer Styling */
.footer {
    font-size: 1rem;
}

.footer-top {
    padding-bottom: 20px;
}

.footer-bottom {
    padding-top: 20px;
    text-align: center;
}

.footer-divider {
    border: 1px solid #555; /* Dark gray line */
    margin: 20px 0;
}

/* Logo Styling */
.footer-logo-frontpage {
    max-width: 200px; /* Adjust the size of the logo */
    height: 150px; 
}

/* Social Icons Styling */
.social-icons {
    display: flex;
    justify-content: center;
    gap: 15px; 
    margin-top: 50px;
    flex-wrap: wrap; 
}

.social-icon {
    width: 40px !important; /* Adjust icon size */
    height: 40px !important;
}

/* Centering the social media icons in their column */
.text-center .social-icons {
    justify-content: center; /* Center the icons horizontally */
}

/* Styling for the buttons in the middle column */
.btn-link {
    font-size: 1rem;
    text-decoration: none;
}

.btn-link:hover {
    text-decoration: underline;
    color: #ccc;
}

/* Google Maps iframe responsive */
iframe {
    border: none;
    width: 80%;
    height: 200px;
}

/* Bottom contact and location text */
.footer h5 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.footer p {
    margin-bottom: 5px;
}

/* Copyright section */
.copyright {
color: #aaa; /* Lighter gray for small text */
font-size: 0.8rem; /* Smaller font size */
}
