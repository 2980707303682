.card {
  max-width: 350px;
  width: auto;
  height: 550px;
  display: flex;
  flex-direction: column;
}

.card-body {
  flex-grow: 1;
  position: relative;
}

.card-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

.hovered .card-img {
  opacity: 0;
}

.hovered .card-img:hover {
  opacity: 1;
}

.card-name {
  margin-top: 10px;
}

.card-desc {
  margin-top: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-price {
  margin-top: 45px;
}

/* Ensure the button is always at the bottom */
.card-body {
  display: flex;
  flex-direction: column;
}

.cart-button {
  margin-top: auto; /* Push the button to the bottom */
  justify-content: end;
  border-radius: 0;
  background-color: #00446a;
}

.cart-button:hover {
  background-color: #00446a;
}
