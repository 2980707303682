/* Styling for the hero section */
.home-page {
    background-color: #ffffff;
}

.home-page .hero-section {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    padding: 100px 0;
    min-height: 90vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    position: relative;
}

/* Optional overlay */
.home-page .hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

/* Ensure the content sits above the overlay */
.home-page .hero-section > * {
    position: relative;
    z-index: 2;
}


.home-page .hero-section h1 {
    margin-bottom: 250px; /* Add vertical space between heading and paragraph */
}

/* Styling for the buttons inside the button group */
.home-page .hero-section .button-group {
    margin-top: 60px;
}

/* Increase the size of the buttons and margin between them */
.home-page .hero-section .button-group .btn {
    margin: 0 20px !important; /* Increase the horizontal margin between buttons */
    padding: 12px 30px; /* Increase the size of the button */
    font-size: 1.2rem; /* Make the button text larger */
}

/* Spacer between the hero section and feature grid */
.spacer-homepage {
    background-color: #003366; /* Gray color */
    height: 80px; /* Set the height of the spacer */
    width: 100%; /* Ensure it takes up the full width */
    margin: 0; /* Remove all margin */
    padding: 0; /* Remove any default padding */
}

.small-spacer {
    background-color: #003366; /* Gray color */
    height: 25px; /* Set the height of the spacer */
    width: 100%; /* Ensure it takes up the full width */
    margin: 0; /* Remove all margin */
    padding: 0; /* Remove any default padding */
}

/* About Section Styling */
.about-section {
    padding: 60px 0; /* Space around the section */
    background: radial-gradient(circle, #66c9f7, #003366); /* Light blue gradient */
}

.about-section h1 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 40px;
}

.about-section p {
    font-size: 1.15rem;
    color: #ffffff;
    text-align: center;
    line-height: 1.6;
}
