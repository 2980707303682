.searchbar-wrapper {
  position: relative; /* Positioning context for dropdown */
  display: flex; /* Use flex for alignment */
  justify-content: center; /* Center the search bar horizontally */
  width: 100%; /* Make wrapper take full width of its container */
  margin: auto; /* Center it within its parent container */
}

.searchbar-form {
  width: 100%; /* Make the form take full width */
}

.searchbar-input {
  width: 100%; /* Ensure input group takes full width */
  max-width: 600px; /* Optional: limit the maximum width */
  margin-bottom: 0; /* Remove any default bottom margin */
}

.search-input {
  width: 100%; /* Full width of the input */
  border-color: #cac6c6 !important; /* Set border color */
}

.search-input:focus {
  box-shadow: none !important; /* Remove focus outline */
}

.search-dropdown {
  position: absolute; /* Position relative to searchbar */
  max-width: 600px;
  top: calc(100% - 20px); /* Position it directly below the search bar */
  left: 0; /* Align to the left of the search bar */
  right: 0; /* Align to the right of the search bar */
  background-color: white; /* Background color of dropdown */
  border: 1px solid #ccc; /* Border for dropdown */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  list-style: none; /* No list style */
  z-index: 1000; /* Ensure dropdown appears above other elements */
}

.search-dropdown li {
  cursor: pointer; /* Pointer cursor for dropdown items */
}

.search-dropdown li a {
  display: block; /* Display block for easy clickability */
  padding: 8px 16px; /* Padding for dropdown items */
  color: black; /* Text color */
  text-decoration: none; /* Remove underline */
}

.search-dropdown li:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

/* New styles for loading spinner */
.loading-spinner {
  padding: 10px;
  text-align: center;
  color: #555;
  font-size: 14px;
}

/* New styles for "No results" message */
.no-results {
  padding: 10px;
  text-align: center;
  color: #555;
  font-size: 14px;
  background-color: white; /* Match dropdown background */
  border-top: 1px solid #ccc; /* Optional: separate from input */
}
