body {
    overflow-x: hidden;
}

.item-sale-cont {
    padding-left: 150px;
    margin-bottom: 150px;
}

.item-page {
    margin-top: 30px;
}

hr {
/* Add styles for the dividing line */
border: 1px solid #000000;
}

p {
/* Add styles for the paragraph containing categories */
font-size: 18px; /* Adjust the font size as needed */
}

/* Base styles for anchor (link) elements */
a {
    text-decoration: none; /* Remove underline */
    color: grey; /* Set text color to grey */
    margin-right: 10px; /* Add some spacing between categories */
    font-size: 16px; /* Adjust the font size as needed */
    transition: color 0.3s ease; /* Smooth transition for color changes */
}

/* Hover styles for anchor (link) elements */
a:hover {
    color: black; /* Change text color to black when hovered */
}


.buy-button {
    border-radius: 0;
    border-color: #384259;
    padding: 15px 30px;
    font-size: 26px;
    background-color: #384259;
  }

.buy-button-col {
    display: flex;
    justify-content: center;
}

.item-price {
    font-size: 2.5rem; /* Adjust the font size as needed */
    font-weight: bold; /* Make the price bold */
    margin-top: 75px; /* Optional: add some space above the price */
    margin-bottom: 50px; /* Optional: add space below the price */
}


.buy-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}
  
.item-categories {
    margin-top: 60px;
}

.desc-cont {
    margin-bottom: 50px;
}

.specs-cont {
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 70px;
    background-color: #6b6b6b46;
}

.group-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add spacing between the buttons */
    margin-top: 10px;
}

.group-item-button {
    position: relative; /* Needed for the checkmark positioning */
    border: 2px solid transparent; /* Default border */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: border-color 0.3s ease-in-out;
}

.group-item-button:hover {
    border-color: #ccc; /* Light gray border on hover for non-selected buttons */
}

.group-item-button.selected {
    border-color: #007bff; /* Blue border for the selected button */
}

.group-item-button.selected:hover {
    border-color: #007bff; /* Ensure the blue border remains when hovering over selected button */
}

.group-item-button.selected::after {
    content: "✓"; /* Unicode for checkmark */
    position: absolute;
    top: 5px; /* Adjust the position as needed */
    right: 5px; /* Adjust the position as needed */
    font-size: 18px;
    color: #007bff; /* Blue checkmark color */
    font-weight: bold;
}
