.image-container {
    margin-top: 30px;
    max-width: 600px; /* Adjust the maximum width as needed */
    max-height: 600px;
    margin: auto; /* Center the Carousel */
}

.carousel img {
    width: 100%; /* Set the image width to 100% of the carousel */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Crop images if necessary */
}
