.cart-item {
    border: none !important; /* Remove the border from ListGroup.Item */
}

.cart-item-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dee2e6; /* Add bottom border to each row */
    padding-bottom: 5px; /* Optional: Adjust spacing between rows */
}

.cart-modal-thumbnail {
    height: 125px;
    width: 125px;
}