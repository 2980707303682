/* CSS for desktop screens */
.footer-logo-eshop {
    margin-top: 15px;
    max-width: 200px; /* Adjust the maximum width as needed */
    height: 100px;
  }
  
  /* CSS for mobile screens */
  @media (max-width: 768px) {
    .footer-logo {
      max-width: 100%; /* Make the image take up 100% of the container's width */
    }
  }

  .footer-logo-cont {
    display: flex;
    justify-content: center;
  }
  
  .follow-us {
    text-align: center;
  }

  .social-icon-row {
    justify-content: center;
  }

  .footer-div {
    background-color: #e6e3e3; /* Set your desired background color */
    width: 100% !important; /* Make the container take up the full width */
    margin: 0;
    padding-top: 15px;
    padding-bottom: 60px;
  }

  .social-icon {
    height: 30px;
    width: 30px;
  }

  .social-icon:hover {
    transform: scale(1.2); /* Make it 10% bigger on hover */
    filter: brightness(1.5); /* Adjust brightness for color change */
  }
  
  .newsletter-cont {
    display: flex;
  }

  /* Horizontal Divider Line */
.footer-divider {
  border: 1px solid #555; /* Dark gray line */
  margin: 20px 0;
}

/* Footer bottom section styling */
.footer-bottom {
  padding-top: 20px;
  text-align: center;
}

.footer-bottom h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Copyright section */
.copyright {
  color: #aaa; /* Lighter gray for small text */
  font-size: 0.8rem; /* Smaller font size */
}

/* Contact us section */
.contact-us p {
  margin-bottom: 5px;
}

.text-center .gemh {
  margin-top: 40px;
}

.payment-methods {
  display: flex;
  gap: 10px; /* Space between icons */
}

.payment-icon {
  width: 40px; /* Adjust the size of the icons */
  height: auto;
}
