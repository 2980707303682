/* Header.css */

/* Ensure the logo has a max width and auto height */
.navbar-brand img {
    margin-left: 50px;
    max-width: 220px;
    height: auto;
  }
  
  /* Center the links horizontally within the navbar collapse */
  .navbar-collapse {
    justify-content: center;
  }
  
  /* Set the background color of the header container to gray */
  .navbar {
    background-color: #e6e3e3; /* Bootstrap's 'gray' variant */
  }
  
  /* Set the color of the navigation links to white */
  .header-nav .nav-link { /* Prefix with .header-nav */
    margin-left: 60px;
    font-size: 1.3rem; /* Adjust the font size */
    color: #363636; /* Make the nav link text white */
    text-decoration: none; /* Remove underline by default */
    position: relative; /* Needed for positioning the pseudo-element */
  }
  
  /* Add a pseudo-element for the underline */
  .header-nav .nav-link::after { /* Prefix with .header-nav */
    content: '';
    position: absolute;
    left: 0; /* Start from the left */
    bottom: -1px; /* 2 pixels below the text */
    height: 2px; /* Height of the underline */
    width: 100%; /* Full width */
    background-color: #003366; /* Initial underline color */
    transform: scaleX(0); /* Start with no visible underline */
    transition: transform 0.3s ease; /* Animation for the underline */
  }
  
  /* Change text color and show underline on hover */
  .header-nav .nav-link:hover { /* Prefix with .header-nav */
    color: #003366; /* Change text color to #003366 on hover */
  }
  
  /* Show underline when hovered */
  .header-nav .nav-link:hover::after { /* Prefix with .header-nav */
    transform: scaleX(1); /* Scale to full width */
  }
  