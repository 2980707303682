.confirmation-container {
  text-align: center;
  padding: 50px 20px;
}

.confirmation-heading {
  font-size: 2.5em;
  color: #28a745;
}

.confirmation-text {
  font-size: 1.2em;
  margin: 10px 0;
}
