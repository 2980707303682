/* Projects.css */

.projects-page {
  padding: 20px;
  background-color: #f9f9f9;
}

.projects-header {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #003366;
}

.project-section {
  margin: 40px 0;
  text-align: left;
}

.project-title {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 20px;
}

.project-image {
  max-width: 100%;
  max-height: 500px; /* You can change the max height as needed */
  object-fit: cover; /* Ensures the image doesn't distort */
}

.project-description {
  font-size: 1.3rem;
  color: #444;
  margin-top: 10px;
  padding-right: 50px;
}

.project-creators {
  margin-top: 10px;
  font-size: 1rem;
  color: #555;
}

.project-image-col {
  display: flex;
  justify-content: center;
}

.project-text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Horizontal line separator between projects */
.project-separator {
  border: 0;
  height: 1px;
  background-color: #003366;
  margin: 50px 0; /* Space above and below the line */
  width: 80%; /* You can adjust the width as needed */
  margin-left: auto;
  margin-right: auto;
}
