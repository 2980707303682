.declined-container {
    text-align: center;
    padding: 50px 20px;
  }
  
  .declined-heading {
    font-size: 2.5em;
    color: #dc3545;
  }
  
  .declined-text {
    font-size: 1.2em;
    margin: 10px 0;
  }
  