/* Style the overall grid container */
.feature-grid {
    margin-top: 50px; /* Add some space between the hero and the grid */
    margin-bottom: 100px;
}

/* Style for each row in the grid */
.grid-row {
    margin-bottom: 20px; /* Space between rows */
}

/* Style for each grid item (both image and text cells) */
.grid-item {
    padding: 20px; /* Add padding inside the text cells */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Remove padding for image cells to ensure the images fill the grid */
.image-cell {
    padding: 0; /* Remove padding from the image cells */
}

/* Image styling */
.grid-image {
    width: 100%; /* Ensure the image fills the width of the grid */
    height: 100%; /* Ensure the image fills the height of the grid */
    object-fit: cover; /* Make sure the image covers the area without distortion */
}

/* Text cell styling */
.text-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-colored {
    background-color: #ffffff; /* Set the background color for text cells */
}

/* Style the text content */
.text-content h2 {
    color: #003366; /* Text color */
    margin-bottom: 10px;
}

.text-content p {
    color: #333;
}
